

.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
  }
  
  .modal.right .modal-content {
    max-height: calc(100vh - 12px ) !important;
    width:500px !important;
    overflow-y: auto;
  }
  
  .modal.right .modal-body {
    padding: 15px 15px 80px;
  }
    
  
  
  .modal.right.fade .modal-dialog {
    right: -130px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal.right.fade.in .modal-dialog {
    right: 0 !important;
    bottom:0;
  }
  
  
  .modal-content {
    border-radius: 0;
    border: none;
  }
  
  .modal-header {
    border-bottom-color: #21386C;
  }
  
  
  .btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #09F;
  color:#fff;
  border:1px solid #EAEAEA;
  }
  
  .btn-demo:focus {
  outline: 0;
  }
  

.custom-task-button
{
    border:1px solid;
    padding:5px;
    background-color : #e8378f;
    border-radius:10px;
    color:#fff;
    float:left;
    font-size:12.5px;
}

.custom-task-button:hover
{
    background-color : #f31f10;
}

.custom-task-button:focus
{
    outline:none !important;
}

.modal-inner-content
{
    width : 100% ;
    /* height :700px ; */
}

.modal-body::-webkit-scrollbar {
    width: 4px;
    margin-right:5px;
  }
  
  
  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(31, 13, 82, 0.3); 
    -webkit-border-radius: 10px;
    margin-right:5px;
    border-radius: 10px;
  }
  
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #272e60;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  }
  
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background-color: #272e60;
  }


  .fa-paperclip:hover
  {
      color:#e8378f;
      cursor:pointer;
  }