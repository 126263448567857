.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none !important;
    border: none;
}

.wrapper {
    width: 100%;
}

#sidebar {
    min-width: 260px;
    max-width: 260px;
    min-height: 400px !important;
    margin-top:2px;
    padding: 17px 10px 0px;
    border-right: 1px solid #ffffff;
    color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

#sidebar .h6 {
    color: rgb(34, 33, 33);
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar.active .custom-menu {
    margin-right: -50px;
}

#sidebar h1 {
    margin-bottom: 20px;
    font-weight: 700;
}

#sidebar h1 .logo {
    color: #fff;
}

#sidebar ul.components {
    padding: 0;
}

#sidebar ul li {
    height: 35px;
    /* line-height: 34px; */
    font-size: 14px;
    width:100%;
    padding: 0;
    /* border-bottom: 1px solid #efedee66 */
}

#sidebar ul li:last-child {
    border-bottom:none !Important;
}


#sidebar ul li>ul {
    /* margin-left: 10px; */
    margin-left: 195px !important;
    margin-top: 1px !important;
}

#sidebar ul li>ul li {
    font-size: 13.5px;
}

#sidebar ul li a {
    /* padding: 10px 0; */
    font-weight: 300;
    font-size: 13px;
    display: block;
    font-weight:300;
    color: #707070;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li a:hover {
    color: #fff;
    text-decoration: none !important;
}

#sidebar ul li.active>a {
    background: transparent;
    color: #fff;
}

@media (max-width: 991.98px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebar .custom-menu {
        margin-right: -71px !important;
        top: 26px !important;
    }
}


#sidebar .custom-menu1 {
    display: inline-block;
    position: absolute;
    top: 0px;
    margin-right: -20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}


#sidebar .custom-menu {
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 5px;
    margin-right: -20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar .custom-menu {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar .custom-menu .btn {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

#sidebar .custom-menu .btn.btn-primary {
    background: #e4e5e6 !important;
    border-color: #ffffff !important; 
}

#sidebar .custom-menu .btn.btn-primary:hover, #sidebar .custom-menu .btn.btn-primary:focus {
    background: #dddddd !important;
    /* border-color: #000000 !important; */
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 991.98px) {
    #sidebarCollapse span {
        display: none;
    }
}

i.bx.bx-dots-horizontal-rounded.dot-icons {
    color: #797979;
}


#content {
    width: 100% !important;
    min-height: calc(100vh - 150px);
    padding: 0;
    border-top-left-radius: 20px;
    background-color:#efefef;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

i#arrow-icon {
    color: #5d5b5b !important;
}



input.search-category:focus {
    background-color: #ffffff;
    outline: none;
}

li.nav-item.nav-inside-item a:hover {
    color: #ffffff!important;
}

li.nav-item.nav-inside-item .d-flex:hover {
    color: #ffffff!important;
}

li.nav-item.nav-inside-item.active .d-flex, li.nav-item.nav-inside-item.active .d-flex button {
    color: #000;
}

li.nav-item.nav-inside-item button:hover {
    color: #ffffff!important;
}

button.nav-link.p-1.navbar-text-ellipsis:active {
    color: #fff;
}

.nav-toggle-workspace .dropdown-menu.multi-level .dropdown-item button.btn-sub-nav:hover > i {
    background-color: initial!important;
    color: #fff;
}


.btn-nav {
    border-width: 0;
    background-color: inherit;
    padding-left: 0;
}

.btn-nav:hover {
    color: #fff;
}

.btn-nav:focus {
    outline-width: 0;
}

.modal-content .modal-body label {
    text-transform: initial;
    font-size: 14px;
    margin-bottom: 0;
    padding: 6px 10px 6px 20px;
}

.btn-nav-toggle {
    padding: 3px!important;
}

/* .btn-nav-toggle:hover {
    background-color: #e6e9ef;
} */

.just-content-sb {
    justify-content: space-between;
}

.btn-nav-toggle::after {
    display: none!important;
}

.dropdown-item .btn-sub-nav {
    font-size: 13.5px;
    font-weight:300;
    background-color: initial;
    border-width: 0;
}

.nav-toggle-workspace .dropdown-item .btn-sub-nav:hover {
    background-color: #0099ff!important;
    color: #ffffff!important;
}

.nav-toggle-workspace .dropdown-item .btn-sub-nav:focus {
    outline-width: 0!important;
}

.nav-toggle-workspace .dropdown-menu.multi-level .dropdown-item button.btn-sub-nav {
    text-align: left;
    width: 100%;
}

.nav-toggle-workspace .dropdown-menu.multi-level .dropdown-item button.btn-sub-nav:hover {
    background-color: initial!important;
}

.nav-toggle-workspace .dropdown-menu.multi-level .dropdown-item button.btn-sub-nav i {
    margin-top: -3px;
}

.category {
    height: calc(100vh - 185px);
    overflow-y: auto;  
    overflow-x: hidden;
    float: left;
    width: 240px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
} 

.category ul li {
    height: auto !important;
}


button.nav-link.p-1 {
    font-size: 13.5px;
    color: #21386C;
    font-weight: 300;
}

/* 
.nav-toggle-workspace.show {
    position: absolute;
    left: 200px;
}
 */


 .nav-toggle-workspace {
    display: block;
    position: relative;
}
