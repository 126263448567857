
::-webkit-scrollbar {
  width: 4px;
  height: 15px !important;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #e8378f;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #e8378f;
}


.chatBody::-webkit-scrollbar {
  width: 4px;
  height: 15px !important;
}
 

.chatBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chatBody::-webkit-scrollbar-thumb {
  background: #e8378f;
}









.icon.formula {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: math;
  font-weight: 600;
  display:none !important;
}

.board-name {
  font-size: 14.5px !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  cursor: text;
  color: #000000;
  display: inline-flex;
  height:28px ;
  align-items: center;
  padding: 0px 2px 0px 2px;
  overflow: hidden;
  outline-color: #09F;
  outline-width: 1px;
}

.board-name:visited {
  outline-width: 1px;
  outline-style: dashed;
  outline-color: #4189f6;
}

.board-name-head:visited {
  outline-width: 1px;
  outline-style: dashed;
  outline-color: #666666;
}

input.workspace-board:focus {
  outline: none;
  border: 1px dashed #333 !important;
  height: 25px !important;
  margin-top: 2px !important;
  margin-left: 2px !important; 
  width: 100% !important;
  transition: none !important;
}

.icon-position {
  vertical-align: text-top;
  font-family: 'myriad-pro';
  font-weight: 300;
  font-size: 14px;
}

a:hover {
  text-decoration: none !important;
}


.board-description {
  font-size: 14px;
  font-weight:300;
  height:59px;
  /* border: 1px dashed #c6c4c4; */
  /* border: 1px inset #a19f9f !important; */
  border-radius: 5px; 
  background-color:#f0f0f0;
  cursor: text;
  color: #666666;
  white-space: normal !important;
  /* display: inline-flex; */
  margin-top:5px;
  min-width: 97%;
  max-width: 97%;
  font-family:'myriad-pro';
  align-items: center;
  padding: 0px 10px 0px 10px;
  overflow: hidden;
  outline-color: #09F;
  outline-width: 1px;
  margin-left:15px;
}


input.search-category {
  border: 1px solid #e0dddd !important;
  border-radius: 6px;
}

input.search-category::placeholder {
  color:#B4B4B4;
}

div#filter {
  width: 100px;
  padding: 5px;
  margin-left: 10px;
}

div#filter:hover {
  background-color: #EAEAEA;
  border-radius: 20px;
}


input.search__input {
  width: 300px;
  height: 29px;
  padding: 3px 24px;
  position: relative;
  top: 7px;
  /* left: 25px; */
  transition: transform 250ms ease-in-out;
  font-size: 14px;
  line-height: 18px;
  color: #575756;
  background-color: #F0F0F0;
  background-image: url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 95% center;
  border-radius: 8px;
  border: 1px solid #ece7e7;
  transition: all 250ms ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  padding-left: 20px;
}


div#dot-icon {
  cursor: pointer;
  margin-left: 20px;
}

div#dot-icon:hover {
  background-color: #EAEAEA;
  width: 40px;
  border-radius: 20px;
}

div#dot-icon i {
  vertical-align: middle;
  font-size: 25px;
  padding: 7px;
}

div#text-table:hover {
  background-color: #F5F5F5;
}

.outline-table {
  margin-top: 20px;
  width: 100%;
  height: auto;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.btn.btn-more {
  background-color: #ffffff;
  color: #111111;
}

.btn.btn-more:hover {
  background-color: #0085ff;
  color: #ffffff;
}

i#tb-icons {
  display: block;
}

.status-fields {
  cursor: pointer;
  height: 34px;
  text-align: center;
  font-size: 13.5px;
}

.status-fields:hover {
  color: #ffffff;
}

i#arrow-down {
  color: #333333;
  cursor: pointer;
}

i#arrow-down:hover {
  color: #09F;
  cursor: pointer;
}

i.active {
  color: #09F;
  cursor: pointer;
}

#content {
  min-height: 400px !important;
  margin-bottom: 20px;
}



.workspace-layout {
  width: auto;
  margin-bottom: 10px;
}

.first-div {
  border: none !important;
  background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.workspace-layout-section-one {
  display: flex;
  background-color: #ffffff;
  padding: 10px;
  width: 99%;
  border-radius: 20px 20px 0px 0px;
  padding: 10px 10px 0px 10px;
  border:2px solid #000 !important;
}

.workspace-layout-section-two {
  background-color: #ffffff;
  padding: 0px 0px 10px;
  width:99%;
}

.workspace-layout-header {
  width: 100%;
  display: flex;
}

.workspace-layout-header h5 {
  font-size: 18px;
  font-weight: 600 !important;
  align-items: flex-end;
  margin-left:7px;
  min-width:90%;
}



div#top-right-content {
  height: 30px;
  margin-top: 4px;
  padding: 6px 16px !important;
  margin-right: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-family: myriad-pro, sans-serif !important;
  border-radius: 5px 5px 5px 5px;
  background-color: #db1c92;
  color: #ffffff;
  font-size: 13.5px;
}

div#top-right-content:hover {
  color: #e0e0dd;
}

div#top-right-content-one {
  height: 30px;
  margin-top: 4px;
  padding: 7px 16px !important;
  margin-right: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-family: myriad-pro, sans-serif !important;
  border-radius: 5px 5px 5px 5px;
  background-color: #4189f6 !important;
  color: #ffffff;
  font-size: 13.5px;
}

div#top-right-content-one:hover {
  color: #e0e0dd;
}

div#description-input {
  background-image: none;
  border: none;
  max-width: 90%;
  white-space: nowrap;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 20px;
  color: #333333;
  margin-top: 10px;
}

div#sort-dropdown {
  margin-top: 5px;
}


div#description-input:focus {
  outline-width: 1px;
  outline-style: dashed;
  outline-color: #666666;
  overflow: scroll;
  height: 80px;
  transition: 0.5s;
  padding-left: 5px;
}

div#description-input span {
  font-size: 13.5px;
  font-weight: 300;
  padding-left: 10px;
}

div#description-input:focus>span {
  display: none;
}

button.nav-link.p-1 {
  color: #707070 !important;
  font-weight: 300 !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.workspace-layout-section-three {
  width: 100%;
  /* border: 1px solid #f1f1f3; */
  /* box-shadow: -1px 5px 5px 0px #ddd7d7; */
  border-radius: 2px;
  justify-content: flex-end;
  padding-left: 14px;
  background-color: #ffffff;
  margin-top: 1px;
  display: flex;
  height: 43px;
  font-family: 'myriad-pro', sans-serif !important;
}

i#dot-icon-workspace {
  vertical-align: middle;
  cursor: pointer;
  padding: 2px;
  font-size: 17px;
  margin-top: -12px;
  margin-left: 10px;
  border: 1px solid;
  border-radius: 30px;
}

i#dot-icon-workspace:hover {
  background-color: #EAEAEA;
  border-radius: 30px;
}

i#dot-icon-workspace-one {
  vertical-align: middle;
  cursor: pointer;
  padding: 2px;
  font-size: 17px;
  margin-top: -3px;
  margin-left: 10px;
  border: 1px solid;
  border-radius: 30px;
}

i#dot-icon-workspace-one:hover {
  background-color: #EAEAEA;
  border-radius: 30px;
}

i#dot-icon-workspace-two {
  vertical-align: middle;
  cursor: pointer;
  padding: 2px;
  font-size: 19px;
  margin-top: -1px;
  margin-left: 10px;
  border: 1px solid;
  border-radius: 30px;
}

i#dot-icon-workspace-two:hover {
  background-color: #EAEAEA;
  border-radius: 30px;
}

.panel-heading {
  padding: 0;
  border: 0;
}

.panel-title>a, .panel-title>a:active {
  display: block;
  padding: 15px;
  color: #555;
  font-size: 16px;
  text-transform: none;
  word-spacing: 3px;
  text-decoration: none;
}

.panel-heading a:before {
  font-family: 'Glyphicons Halflings';
  content: "\e114";
  float: right;
  transition: all 0.5s;
}

.panel-heading.active a:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* 
.dropdown-menu.show1 {
  margin-top: 30px !important;
} */

/* .dropdown-menu.show {
  margin-left: 0px !important;
  margin-top: 35px !important; 
} */


.navbar-text-ellipsis:hover li.nav-item.nav-inside-item.active
{
  background-color: #4288f5;
}

li.nav-item.nav-inside-item.active i.bx.bx-dots-vertical-rounded.dot-icons{
  color:#fff !important;
}



.dropdown .dropdown-toggle:after {
  content: none !important;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: auto !important;
  left: 100% !important;
  margin-top: -20px;
  margin-left: 0px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
  transition: all 2s ease-in-out !important;
}



.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #666666;
  margin-top: 3px;
  margin-left: 10px;
  margin-right: -10px;
}

.dropdown-submenu a {
  text-transform: none;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

ul.dropdown-menu li {
  line-height: 30px;
}

ul.dropdown-menu li:hover {
  background-color: #09F;
  border-radius: 5px;
}

ul.dropdown-menu li:hover>a {
  color: #ffffff;
}

ul.dropdown-menu li a {
  color: #000000;
  text-transform: none;
}

ul.dropdown-menu li a:hover {
  background: none !important;
}

#sort-dropdown>button:hover {
  border-radius: 6px !important;
  background-color: #4288f5 !important;
  color: #fff;
}

#sort-dropdown>button::selection
{
  border-radius: 6px !important;
  background-color: #4288f5 !important;
  color: #fff;
}

#sort-dropdown>button:focus span {
  font-weight: bold;
}

#sort-dropdown>button:focus i {
  font-weight: bold;
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.2s;
    -webkit-animation-duration: 0.2s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(2rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

@media (min-width: 992px) {
  .animate1 {
    animation-duration: 0.2s;
    -webkit-animation-duration: 0.2s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn1 {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn1 {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(2rem);
    -webkit-opacity: 0;
  }
}

.slideIn1 {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

ul.filter-list {
  list-style-type: none;
  float: left;
  padding: 0px !important; 
  width:100%;
}

ul.filter-list h6 {
  font-size: 13.5px !important;
  font-weight: 400 !important;
  min-width: 130px !important;
  font-family: myriad-pro, sans-serif !important;
}

ul.filter-list li {
    background-color: #ffffff;
    border-radius: 2px;
    height: 28px;
    width: 150px;
    margin-top: 2px;
    margin-bottom: 8px;
    border: 1px solid #e2dede;
    padding: 1px 5px;
    display: flex;
    justify-content: space-between;
}

ul.filter-list li:hover {
  background-color: #dbd9d9;
  border-radius: 1px;
}

ul.filter-list li .btn {
  padding: 1px 2px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  width:80%;
  text-overflow: ellipsis;
  color:#5f5d5d;
}

ul.filter-list li span {
  font-size: 10px;
  color: #4b4848;
  font-weight: 100;
  border: 1px solid #EAEAEA;
  background-color: #EAEAEA;
  margin: 3px 0px;
  padding: 7px !important;
  display: flex;
  align-items: center;
  background-color: #4287f500;
  border-radius: 0px;
  justify-content: center;
}


span.filter-count {
  background-color: #EAEAEA !important;
}


.panel-heading a:before {
  content: "" !important;
}

.panel-title .collapsed .bx {
  transform: rotate(-90deg);
}

.modal-header {
  padding: 1rem 0rem !important;
  border-bottom: none !important;
}

.modal-header .close {
  margin-right: -1px!important;
}

.dropdown-toggle:after {
  margin-right: 15px !important;
}

.tooltip-inner {
  max-width: 100% !important;
  cursor: pointer;
}

i#inner-round-dot {
  margin-top: 18px;
  cursor: pointer;
  font-size: 25px;
}

i#inner-round-dot:hover {
  color: #09f;
}


.myButton {
  padding: .2em 1em;
  font-size: 1em;
}

.mySelect {
  padding: .2em 0;
  font-size: 1em;
}

#myDiv {
  color: Green;
  background-color: #eee;
  border: 2px solid #333;
  display: none;
  text-align: justify;
}

#myDiv p {
  margin: 15px;
  font-size: 0.917em;
}

div.activity-right-div {
  width: 800px;
  height: 100%;
  border-radius: 20px;
  box-shadow: -2px 1px 11px 0px #C5C5C5;
  position: absolute;
  display: none;
  z-index: 9999999;
  background-color: #ffffff;
  /* right : calc( 100% ); */
}

div.activity-right-div h5 {
  color: #336;
  padding: 20px;
  font-weight: 400;
  font-size: 25px;
}

.modal.left .modal-dialog, .modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
}

.modal.left .modal-content, .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body, .modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/

.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/

.modal.right.fade .modal-dialog {
  right: 320px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */

.modal-content {
  border-radius: 0;
  border: none;
  overflow: initial;
}

.modal-header {
  border-bottom-color: #EEEEEE;
  background-color: #21386C;
  color: #ffffff;
  padding: 10px !important;
}

.modal-header .modal-title {
  color: #fff;
}

/* -- Board Table Styles Starts Here -- */

div.TableLayout-First {
  width: 100%;
  height: 500px;
  overflow-x: scroll;
  overflow-y: scroll;
}

div.Table-Header {
  width: 100%;
  margin-top: 30px;
  height: 35px;
  display: flex;
}

.Table-Header-Dropdown {
  width: 15px;
  height: 30px;
  padding-top: 10px;
}

.Table-Header-Dropdown i {
  color: #09F;
}

.Table-Header-Dropdown i:hover {
  color: rgb(6, 119, 194);
  cursor: pointer;
}

div.Table-Header-Column {
  width: 50%;
  height: 30px;
  display: flex;
  margin-left: 1px;
}

div.Table-Header-Column span {
  padding: 10px 10px 10px 10px;
  font-weight: 300;
  font-size: 14px;
}

div.Table-Row-Column {
  width: 50%;
  height: 30px;
  display: flex;
  margin-left: 1px;
  cursor: pointer;
  background-color: #f1f1f3;
  border-bottom: 1px solid #dfdfe0;
}

div.Table-Row-Column:hover {
  background-color: rgb(223, 221, 221);
}

div.Table-Row-Column span {
  padding-left: 10px;
  padding-top: 6px;
  font-size: 13px;
}

div.Table-Row-Strip {
  width: 15px;
  height: 30px;
  border-radius: 3px;
  background-color: #09F;
}

div.Table-First-Row {
  display: flex;
  margin-top: 1px;
}

/* -- Board Table Styles Ends Here -- */

/* --- Workspace Styles Starts Here --- */

#favourites-star {
  font-size: 25px;
  color: #EAEAEA;
  cursor: pointer;
}

i.bx .bxs-analyse {
  vertical-align: middle;
  color: #ffffff;
}

i#arrow-icon {
  font-size: 20px;
  color: #ffffff;
  position: relative;
  top: -3px;
  left: -9px;
  cursor:pointer;
}

.workspace-ellipsis {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.workspace-ellipsis h4 {
  color: #000000;
  font-size:18px;
  position: relative;
  top: 9px;
}


i.icon-style {
  vertical-align: sub !important;
  color: #000000;
  margin-right: 5px;
  /* margin-top: -7px !important; */
  position: relative;
  top: -2px;
  left: 5px;
  margin-right:13px;
}

.search-icon-style {
  vertical-align: text-bottom;
  font-size: 20px;
  color: #b4b4b4;
  position: absolute;
  top: 78px;
  left: 25px;
}

.main-panel {
  width: 264px;
  margin-left: -20px;
}

.main-panel .panel-title {
  display: flex;
  height: 53px;
}

.main-panel .panel-title a {
  font-weight: bold;
  display: flex;
  text-decoration: none;
}

.Letter-Icon-Mainworkspace {
  background-color: #e8378f;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  vertical-align: middle;
  padding: 8px 0px 0px 9px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 11px;
  color: #ffffff;
  display: flex;
}

span.workspace-title {
  margin-top: 15px;
}

.collapse-left-icon {
  margin-top: 15px;
}

.workspace-dropdown {
  display: flex;
}

.workspace-dropdown>button {
  display: flex;
}

.workspace-dropdown>.dropdown-menu {
  font-family: myriad-pro;
  font-weight: 300 !important;
  margin-left: 20px;
  margin-top: 50px;
}

.workspace-dropdown>.dropdown-menu i {
  vertical-align: middle;
  color: #000000;
}

ul.color-dropdown {
  width: 300px;
  height: 42 0px;
  padding: 50px;
}

ul.color-dropdown h4 {
  font-size: 14px;
  text-transform: none;
  margin-left: 10px;
  margin-top: 10px;
}

ul.color-dropdown>div.color-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.workspace-icon-color {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  cursor: pointer;
  margin: 4px;
}

.color-shape-icon-wrapper {
  width: 250px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-bottom: 20px;
}

.color-shape-icon-wrapper>div {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  font-size: 22px;
  background-color: red;
}

div.subworkspace-modal {
  width: 500px;
  margin: 200px auto;
  padding: 30px;
  border-radius: 8px !important;
}

div.subworkspace-modal .modal-header {
  border-bottom: none !important;
}

div.subworkspace-modal .modal-header span {
  font-size: 27px;
}

div.Board-modal {
  width: 500px;
  height: 300px !important;
  margin: 200px auto;
  padding: 30px;
  border-radius: 8px !important;
}

div.Board-modal .modal-header {
  border-bottom: none !important;
}

div.Board-modal .modal-header {
  font-size: 27px;
}

div.subworkspace-panel {
  width: 209px;
}

div.panel-text-wrap {
  display: flex;
  width: 221px;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: space-between;
}

div.panel-text-wrap-one {
  display: flex;
  width: 232px;
  padding-left: 20px;
  margin-top: -10px;
  justify-content: space-between;
}

.panel-text-wrap a {
  font-weight: bold;
  color: #333333;
  display: flex;
  text-decoration: none !important;
}

div.panel-text-wrap-one a {
  font-weight: bold;
  color: #333333;
  display: flex;
  text-decoration: none;
  height: 25px;
}

div.Letter-Icon-Subworkspace {
  background-color: green;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  vertical-align: middle;
  padding: 5px 2px 3px 11px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 11px;
}

div.Letter-Icon-Board {
  background-color: blue;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  vertical-align: middle;
  padding: 5px 2px 3px 11px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 11px;
}

div.Letter-Icon-Board span {
  margin-top: 15px;
}

div.Letter-Icon-Subboard {
  background-color: red;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  vertical-align: middle;
  padding: 5px 2px 3px 6px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 5px;
}

div.Letter-Icon-Subboard {
  margin-top: -5px;
}

div.panel-text-wrap-two {
  display: flex;
  width: 210px;
  padding-left: 26px;
}

div.panel-text-wrap-two a {
  font-weight: bold;
  color: #333333;
  display: flex;
  text-decoration: none;
}

.bg-board-header {
  background-color: #09F;
}

.dropdown-menu .dropdown-item.change-item {
  text-transform: capitalize;
}

.btn-add-column {
  text-transform: unset !important;
}

.dropdown-menu .change-item.change-status {
  color: #ffffff;
  margin-bottom: 1px;
  margin-top: 2px;
  min-height: 30px;
}

.dropdown-menu .change-item.change-status:hover {
  background-color: #28357e!important;
}

.dropdown-menu.status-dropdown {
  min-width: 250px!important;
  left: 62%!important;
}


.dropdown-menu .dropdown-item.btn-group-action {
  text-transform: capitalize;
}

.dropdown-menu .dropdown-item.btn-group-action:focus {
  background-color: #a3afbd;
  border: 1px solid #fff!important;
  outline-color: #a3afbd;
}

.btn-primary {
  background-color: #007bff!important;
  border-color: #007bff!important;
}

.btn-status {
  padding: 8px 10px!important;
  border-radius: 1px !important;
}

.btn-status:hover {
  color: #ffffff!important;
  background-color: #0085ff;
}

textarea.form-control {
  border-radius: 3px;
}

.__react_component_tooltip {
  white-space: nowrap;
}

input.form-cell-input {
  text-align: center;
  height: 100%;
  border: 1px dashed #111111 !important;
}

input.form-cell-input:focus {
  border: 1px dotted #111111 !important;
  outline-width: 0ch !important;
}

input.workspace-board {
  border-width: 0ch;
  min-height: 25px;
  font-weight:300;
  font-size: 13.5px;
  position: sticky;
  left: 4px;
  flex: 0 1 10%;
}

.group-title-name{
    position: sticky;
    left: 33px;
    flex: 0 1 10%;
}

.board-bottom {
  min-width: 160px;
  width: 100%;
}

.board-bottom .empty-item {
  height: 100%;
  min-height: 20px;
}

.number-bottom {
  position: relative;
}

.number-bottom .number-bottom-change {
  position: absolute;
  min-width: 450px;
  min-height: 200px;
  border-radius: 6px;
  box-shadow: 0 4px 17px 6px rgba(117, 114, 114, 0.3);
  background-color: #ffffff;
  z-index: 99999;
  top: 0;
  right: 168px;
  display: none;
}

.number-bottom .number-bottom-change.show {
  display: block;
}

.number-bottom .number-bottom-change .change-title {
  font-size: 17px;
  font-weight: 300;
}

.number-bottom .number-bottom-change .change-action {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

input.number-unit {
  border-width: 0ch;
  font-size: 14px;
  padding: 0;
}

input.tags-input {
  width: 100%;
  border-width: 0ch;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  border-bottom: 1px solid #f1f1f3;
  padding: 0;
}

.number-bottom .number-bottom-change .change-action>div {
  text-align: center;
  cursor: pointer;
}

.number-bottom .number-bottom-change .change-action span {
  font-size: 14px;
}

.number-unit-item.active {
  border-color: #007bff !important;
  color: #007bff;
}

.react-tags__search-input {
  width: 100% !important;
  outline-width: 0ch;
  cursor: pointer;
  border: 1px dotted #333333;
  padding: 0.48ch;
}

.react-tags__search-input:focus {
  border: 1px dotted #333333;
  padding: 0.4ch;
}

/* .dropdown-tags-header {
  position: relative;
  z-index: 9999;
} */

.dropdown-tags-body {
  position: absolute;
  top: 2px;
  left: -105px;
  display: none;
}

.dropdown-tags-body.show {
  display: block;
}

.dropdown-tags-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-tags-list {
  z-index: 999;
  align-self: flex-end;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3px 6px;
  border: 1px solid #0085ff;
  border-radius: 1px;
  margin-bottom: 16px;
  min-width: 152px;
  min-height: 32px;
  max-height: 500px;
  overflow-y: auto;
}

.dropdown-tags-edit {
  min-height: 100px;
  width: 280px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 4px 17px 6px rgba(117, 114, 114, 0.3);
  border-radius: 6px;
  position: relative;
}

.dropdown-tags-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

.dropdown-tags-item .dropdown-tag {
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
}

.dropdown-tags-item .dropdwon-tag-remove {
  border-radius: 6px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  margin-top: 1px;
}

.dropdown-tags-item .dropdwon-tag-remove i {
  font-size: 11px;
}

.dropdown-tags-edit-add .suggetion-tag {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
}

.dropdown-tags-edit-add .suggetion-tag:hover {
  background-color: #007bff;
  color: #fff !important;
}

.dropdown-tags-edit-add .suggetion-tag:hover .suggetion-tag-name {
  color: #fff !important;
}

.suggetion-tag-button {
  background-color: #f1f1f3;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 10px;
  color: #007bff;
}

.suggetion-tag-button:hover {
  background-color: #007bff;
  color: #fff !important;
}

.add-hint-component {
  color: #0085ff !important;
  font-size: 16px;
  align-items: center;
  z-index: 2;
}

.tags-cell-component {
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.tags-cell-component:hover {
  padding-left: 24px;
}

.tags-cell-component .add-hint-component.add-button {
  position: absolute;
  top: 11px;
  left: 8px;
  transform: scale(0.3);
  transform-origin: 50% 50%;
  opacity: 0;
  visibility: hidden;
}

.tags-cell-component:hover .add-button {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.tags-cell-component:hover .add-button i {
  color: #007bff !important;
}
.react-datepicker-popper{
  width: 370px !important;
}
.tags-cell-component .tag-prevent-open-dialog {
  display: flex;
  flex: 0 1 auto;
  min-width: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: normal;
  cursor: pointer;
  width: 190px !important;
}

.tag-prevent-open-dialog:hover {
  text-decoration: underline;
}

.tag-prevent-open-dialog:not(:first-child) {
  padding-left: 4px;
}

.ds-text-component {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 0 2px;
}

/* .dropdown{
  line-height:33px !important;
} */

.dropdown-cell-item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 34px !important;
  justify-content: center;
  cursor: pointer;
  border-left:1px solid #EAEAEA;
}

.dropdown-option-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-cell-item .add-button {
  position: absolute;
  top: 11px;
  left: 4px;
  transform: scale(0.3);
  transform-origin: 50% 50%;
  opacity: 0;
  visibility: hidden;
}

.dropdown-cell-item:hover .add-button {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.dropdown-cell-wrapper:hover .dropdown-option-wrapper {
  padding-left: 24px;
}

.dropdown-cell-item .dropdown-option-wrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}

.dropdown-cell-item .dropdown-option-wrapper .option-names {
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 0;
}

.dropdown-cell-item .dropdown-option-wrapper .option-names .option-name {
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
  background-color: #e5f4ff;
  border-radius: 24px;
  margin: 2px;
  padding: 0 4px;
  line-height: 24px;
  color: #0085ff;
  font-size: 12px;
}

.dropdown-cell-item .dropdown-option-wrapper .option-names .option-name.cell_focused {
  background-color: #ffffff;
}

.dropdown-counter-component {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  width: 8px;
}

.dropdown-counter-component .dropdown-counter-text {
  padding: 2px 4px;
  line-height: 17px;
  min-width: 22px;
  max-width: 28px;
  height: 22px;
  font-size: 10px;
  color: white;
  margin-left: 8px;
  border-radius: 20px;
  background-color: black;
}

.drowpdown-edit {
  display: none;
  position: absolute;
  top: 2px;
  left: -45px;
  z-index: 99;
  width: 240px;
  min-height: 100px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 17px 6px rgba(117, 114, 114, 0.3);
}

.drowpdown-edit.show {
  display: block;
  background-color: #21376b;
}

button.btn.btn-add.w-100.d-flex.align-items-center.text-left {
  color: #fff;
  border: 1px solid #db1b92;
  background-color: #db1b92;
} 

.dropdown-chosen-options-wrapper {
  margin-bottom: 8px;
}

.multiple-items-box-component {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 2px 2px 0;
}

.single-box-item-component {
  display: flex;
  align-items: center;
  transition: flex-shrink 0.15s ease-in;
  margin: 4px;
  min-width: 0;
  background: #e5f4ff !important;
  border: 1px solid #f7f7f7;
  color: #333333;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 400;
  height: 22px;
  box-sizing: content-box;
}

.multiple-items-box-component .item-tokens-line {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.multiple-items-box-component .item-token {
  margin: 4px;
  min-width: 0;
  max-width: calc(100% - 8px);
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  color: #333333;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 400;
  height: 22px;
  box-sizing: content-box;
  max-width: calc(100% - 8px);
  padding-left: 4px;
}

.single-box-item-component .item-wrapper {
  flex-shrink: 1;
  min-width: 0;
}

.dropdown-item-name {
  padding-left: 4px;
  color: #0085ff;
  overflow: hidden;
  min-width: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 100%;
  font: inherit;
  text-transform:lowercase;
}

.single-box-item-component .clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  font-size: 10px;
  border-radius: 100%;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 2px;
  flex-shrink: 0;
}

.single-box-item-component .clear-button:hover {
  background: #ffffff;
}

.add-dropdown-item {
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  padding: 4px 6px;
  border: 1px solid #dfdfe0;
}

.add-dropdown-item:focus {
  border-color: #0085ff !important;
}

.dropdown-label-list {
  position: relative;
  max-height: 160px;
  width: 100%;
  overflow: auto;
}

.dropdown-label-suggest .dropdown-label-item {
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;
  color: #ffffff;
}

.dropdown-label-suggest .dropdown-label-item:hover {
  background-color: #0085ff;
  color: #ffffff;
}

.btn.btn-add {
  color: #0085ff;
}

.btn.btn-add:hover {
  background-color: #0085ff;
  color: #ffffff;
}

.dropdown-label-edit-text:hover {
  border: 1px dotted #666666;
}

.filter-cell::-webkit-scrollbar {
  width: 4px;
}

/* Track */

.filter-cell::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.filter-cell::-webkit-scrollbar-thumb {
  background: #e8378f;
}

/* Handle on hover */

.filter-cell::-webkit-scrollbar-thumb:hover {
  background: #e8378f;
}

.filter-component::-webkit-scrollbar {
  height: 8px;
}

.date-item-close {
  font-size: 10px;
  font-weight: 100;
  border: 1px solid;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f8f9fa;
  background-color: #f8f9fa;
  cursor: pointer;
}

.date-component:hover .date-item-close {
  background-color: #a3afbd;
}

.btn.add-sort:hover {
  background-color: #cdcddb;
}

.sort-option {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort-option .remove-sort {
  border: 1px solid #ffffff !important;
  font-size: 11px;
  padding: 2px;
  border-radius: 50%;
  margin-left: 10px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-sort i {
  color: #fff;
}


.sort-option .remove-sort:hover {
  background-color: rgb(226, 68, 92);
  border-color: #ffffff;
  color: #fff;
}

.sort-option .remove-sort:hover i {
  color: #ffffff !important
}

select.form-control:not([multiple=multiple]) {
  background-position: calc(100% - 12px) 10px, calc(100% - 20px) 7px, 100% 0;
}

.status-bottom {
  width: 100%;
  height: 50px;
  margin-top:-1px;
  background-color: #efefef !important;
}

.status-bottom .bottom-content {
  width: 100%;
  display: flex;
  cursor: pointer;
}

.status-bottom .bottom-content .status-item:hover {
  transform: scale(1.25, 1.25);
}

.status-item:hover::before {
  content: attr(data-title);
  position: absolute;
  top: -30px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
}

.status-item:hover::after {
  content: '';
  position: absolute;
  bottom: 23px;
  left: 8px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-top: 8px solid #000;
}

.status-bottom-dropdown {
  position: absolute;
  top: -34px;
}

.status-bottom-dropdown ul li {
  padding: 1px 5px;
  cursor: pointer;
}

.status-bottom-dropdown ul li label {
  color: #000000;
  font-weight: 300 !important;
  font-size: 16px;
  text-transform: inherit;
  cursor: pointer;
}

.status-bottom-dropdown ul li:hover {
  background-color: #f1f1f3;
}

.status-bottom-dropdown .radio-field {
  display: flex;
  align-items: center;
}

.status-bottom-dropdown .radio-field label {
  margin-bottom: 0;
  padding-left: 5px;
}

.radio label {
  color: #000000;
}

.radio label.terminology::after {
  left: 25px;
}

.date-bottom {
  position: relative;
  height:41px !Important;
  background-color:#efefef !important;
}

.date-bottom .date-bottom-content {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.date-bottom .date-bottom-content .date-bottom-range {
  width: 100%;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius:0px !important;
}

.date-bottom .date-bottom-content .date-bottom-range span {
  font-size: 12px;
  color: #ffffff;
}

.date-bottom .date-bottom-content .date-bottom-item {
  padding: 2px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.date-bottom .date-bottom-content .date-bottom-item .date-bottom-earliest {
  font-size: 12px;
}

.date-bottom .date-bottom-content .date-bottom-item .date-bottom-type {
  font-size: 10px;
}

.board-cell-item {
  width: 100%;
}


.row.bg-white.pt-2.border.shadow.position-absolute.reminder-dropdown {
  top: auto !important;
}

.board-cell-item .board-cell-item-body {
  min-height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formula-dropdown {
  width: 400px;
  top: 0 !important;
  margin-top: 0px !important;
}

.formula-dropdown .formula-content .formula-editor {
  border-bottom: 1px solid #f0f0f0 !important;
}

.formula-dropdown .formula-content .formula-editor .formula-editor-area {
  width: 300px;
  height: 70px;
  color: #006acc;
  font-family: monospace;
  outline: none;
  border: 1px solid #c4c4c4;
  background-color:#f0f0f0 !important;
  border-radius: 5px;
  font-family: 'myriad-pro' !important;
  font-weight: 400;
  padding-left: 10px;
  line-height: 18px;
}

.formula-nav {
  border-right: 1px solid #f0f0f0 !important;
  height: 99%;
  overflow-y: auto;
}

.flex-column.sub-nav {
  line-height: 1.2;
  padding-left: 5px !important;
}

.flex-column.sub-nav li {
  list-style: none;
  cursor: pointer;
  padding: 4px 5px;
  border-radius: 2px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight:300;
}



.flex-column.sub-nav li:hover, .flex-column.sub-nav li.active {
  background-color: #007bff;
  color: #ffffff;
}

.formula-function-details {
  height: 300px;
}

.formula-item {
  margin-top: 5px;
}

.formula-item h5 {
  color: #000000;
  font-weight: 600;
  font-size: 18px;
}

.formula-item p {
  font-size: 13px;
  margin-bottom: 1px;
  color: #5f5d5d !important;
  font-weight:300;
}

.people-info {
  position:absolute;
  bottom: 0;
  width: 250px !important;
  z-index: 9999;
  left: 0;
  top:0;
}

.people-info-body.p-2 h5 {
  font-size: 16.5px;
  font-weight: 300;
}

.people-info-body.p-2 p {
  font-size: 14px;
  font-weight: 300;
}


.people-info .people-info-content {
  border-radius: 6px;
  padding-top: 10px;
  background-color: #ffffff;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, .3);
}

.people-info .people-info-content .people-info-footer {
  margin-top: 10px;
  border-top: 1px solid #f0f0f0 !important;
  position: relative;
  font-weight:300;
}

.people-info .people-info-content .people-info-footer .people-info-contact {
  position: absolute;
  width: 250px !important;
  left: 0;
  top: 38px;
}

.people-info .people-info-content .people-info-footer .people-info-contact .people-info-contact-detail {
  border-radius: 6px;
  background-color: #ffffff;
  font-size:12.5px;
  box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, .3);
  margin-top: 6px;
  padding: 10px 15px;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .holder.card {
  margin: 0 0 1rem 0 !important;
} */

.card.group-row {
  margin: 0 0 1rem 0 !important;
  padding: 0;
}

.modal.right.fade .modal-dialog {
  right: -142px !important;
}

.modal.right .modal-header {
  background-color: #ffffff;
  border-bottom: 1px solid #dee2e6 !important;
}

.modal.right .modal-header .modal-title span {
  font-family: initial !important;
  font-size: 24px;
  font-weight: 600;
  color: #111111 !important;
}

.modal.right .modal-content .modal-header .close {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.right-side {
  height: 100%;
  width: 100%;
  z-index: 9999;
  position: absolute;
  top: 10;
}

.right-side .right-side-content {
  min-width: 400px;
  position: absolute;
  right: 282px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -2px -0px 5px 0px #333333;
}

.ResizePanel-module_ContainerHorizontal__1uF_- {
  height: 100%;
}

.right-side .right-side-content .right-side-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: 1px solid #333333;
}

.right-side .right-side-content .right-side-header .right-side-header-title {
  margin-bottom: 0!important;
  font-family: initial;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.right-side .right-side-content .right-side-header .right-side-close {
  cursor: pointer;
}

.right-side .right-side-content .right-side-body {
  padding: 12px 5px;
}

.right-side .right-side-content .right-side-body .right-side-tab {
  cursor: pointer;
}

.activity-logs {
  display: flex;
  align-items: center;
  padding: 10px 2px;
  border-bottom: 1px solid #dee2e6;
}

.activity-logs .activity-time {
  padding: 1px 5px;
  min-width: 60px;
  width: 100%;
}

.activity-logs .activity-type {
  min-width: 50px;
  padding: 1px 5px;
}

.activity-logs .activity-name {
  min-width: 80px;
  padding: 1px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity-logs .activity-content {
  padding: 1px 5px;
  white-space: nowrap;
  overflow: hidden;
}

.activity-content .activity-status {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.activity-update {
  font-size: 14px;
}

.auto-number-item {
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight:300;
}

/* 2021.1.12 */

/* 3+ image preview plus style */

.more-plusFile-count {
  background: black;
  color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

/* image preview hover style */

.file-image-thumbnail:hover {
  zoom: 1.2;
}

/* hover thumbnail control icon hover style */

.thumbnail-control-icon:hover {
  background: #a9a9a9;
  border-radius: 4px;
}

/* location element hover style */

/* .location-element {
  border: 1px dashed transparent;
}

.location-element:hover {
  border: 1px dashed gray;
} */

/* location search input style */

.location-search-input {
  background: transparent;
  border: 1px dashed transparent;
  font-weight:300;
  color:#353434;
}

.location-search-input:focus {
  background: white;
  border: 1px dashed gray;
  outline: none;
}

/* longText  */

.long-text-element {
  border: 1px dashed transparent;
}

.long-text-element:hover {
  border: 1px dashed gray;
}

/* progress bar style in progress tracking column */

progress[value]::-webkit-progress-bar {
  background-color: white;
}

progress[value]::-webkit-progress-value {
  background-color: #00ca72;
}

.board-permission-header {
  padding: 12px 5px;
  font-size: 18px;
  font-weight: 600;
}

.board-permission-footer {
  padding: 12px 5px;
  font-size: 14px;
  font-weight: 600;
}

.board-permission-detail {
  height: 290px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding: 10px 12px;
  background-color: #e5f4ff;
}

.board-permission-detail-content {
  padding: 10px 16px;
}

.card-body.active {
  background-color: #e5f4ff;
  border-color: #0085ff;
}

.permission-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permission-check {
  font-size: 14px;
  font-weight: 300;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  background-color: #0099ff;
  color: #ffffff;
  border-radius: 50%;
}

.board-type-content {
  font-size: 16px;
  border: 2px dotted #000;
}

.board-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.board-type-icon i {
  font-size: 56px!important;
}

.board-acitivty-last-viewed {
  display: flex;
  align-items: center;
  justify-content: space-between;
}




/* @media(min-width: 1400px) and (max-width: 1599px) {
  .fullscreen {
    width: 100%;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .fullscreen {
    width: 100%;
  }
} */

.fullscreen {
  min-width: 100;
  height:100;
}


.fullscreen-enabled {
  background: #fff;
  overflow: auto;
}

.last-updated-item .last-updated-item-content {
  height: 37px;
  padding: 2px 8px;
}

.last-updated-item .last-updated-item-content .last-updated-item-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.last-updated-item-content.data-title:hover::after {
  bottom: 30px;
}

.last-updated-item-content.data-title:hover::before {
  left: 0
}

.column-header.data-title:hover::after {
  bottom: 12px;
}

.column-header.data-title:hover::before {
  left: -7px;
  top: -20px;
}

i.bx.bxs-plus-circle.dot-icons {
  font-size: 30px;
  color: rgb(219, 28, 146);
  position: relative;
  top: -1px;
  cursor: pointer;
}


i.fal.fa-chevron-down.column-down-arrow {
  display: none;
}

.d-inline-flex.column-header:hover i.fal.fa-chevron-down.column-down-arrow 
{
  display: block;
  transition:2s;
} 


i.bx.bx-grid-vertical.grid-vertical.text-black-50.cursor-pointer.drag-vertical-column
{
  display:none;
}

.d-inline-flex.column-header:hover i.bx.bx-grid-vertical.grid-vertical.text-black-50.cursor-pointer.drag-vertical-column
{
  display: block;
  transition:2s;
  position:absolute;
}



i.bx.bxs-folder {
  color: #fad42d;
  position:relative;
  top:3px;
  margin-right:5px;
}

i.fa.fa-square {
  color: #fe96d6;
}


li.nav-item.nav-inside-item .d-flex:hover {
  color: #000000!important;
}

li.nav-item.nav-inside-item a:hover {
  color: #000000!important;
  background-color:#ffffff !important;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #ffffff !important; 
  text-decoration: none !important;
  background-color: #ffffff00 !important;
  border-radius:5px !important;
}

.dropdown-item:hover > i {
  color: #ffffff !important; 
  margin-left:10px;
  transition:0.3s;
  
}


.dropdown-menu+i:hover {
  color: #ffffff !important;
}


li.nav-item.nav-inside-item button:hover {
  color: #ffffff!important;
}


.border-radius {
  border-radius: 15px;
}



.star-ratings {
  position: relative;
  top: 0px;
}


ul.dropdown-menu.multi-level.animate.slideIn.show {
  border-radius: 8px !important;
}


ul.dropdown-menu.multi-level.show {
  border-radius: 8px !important;
}


.board-name.status-names {
  margin-top: -9px;
}



.left-section-cog
{
  color: rgb(83 84 84) !important;
  font-size: 19px;
  border-radius: 20px;
  padding: 2px;
  position: relative !important;
  top: -1px !important;
  font-size:24px !important;
}


button#navbarCollapse1 {
  margin-top: -15px;
}



.bxs-plus-circle:hover
{
  color:#4189f6 !important;
  cursor:pointer
}


/* input.form-check-input
{
  margin-top:10px;
} */

.filter-head
{
    background-color: #e8378f;
    padding: 5px;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
}

button.btn.btn-primary.btn-sm.float-left {
  width: 100%;
}


.right-arrow-icon
{
    color:yellow;
    position: relative !important;
    top: -4px !important;
    right: 10px;
    font-size: 20px;
    display:none;
}




.navbar-slide
{
  position:relative;
  left:-10px;
}




.list__item:nth-last-child()
{
  position:sticky;
  left:0;
}


.group-head
{

  /* position:sticky; 
  top:0;
  left:0; */
  /* z-index:1000; */
  /* width: 1200px; */
  box-shadow: -2px 1px 5px 1px #e2dddd;
  /* position: sticky;
  top: -1px;
  left: 0;
  z-index: 5; */
  height:35px;
}


.group-title-name
{
  align-items: center !important;
  height: 100%;
}

.flex-item.border-hearder.mb-1.bg-white.none
{
  height:40px !important;
}



.table-collapse-icon
{
  background-color:#707070 !important;
  color:#fff !important;
  cursor:pointer;
  border-radius:20px;
  font-size:17px !important;
}

.table-collapse-icon:hover
{
  background-color:#3a3939 !important;
  color:#fff !important;
  transition:0.5s;
  transform:rotate(360deg);
}

span.collapse-title > span {
  color: #fff !important;
}

span.collapse-title > span > i {
  color: #fff !important;
}


.DragingHover > .column-header
{
  border:1px solid #000;
}


table, th, td {
   border: none !important;
}


div#chatIM {
  position: relative;
  top: 5px;
  padding: 5px;
  float:right;
  /* width:100px; */
} 



.react-datepicker__header
{
  background-color: #007bff !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header
{
  color:#fff !important;
}

/* 
 .d-flex.group-head:before {
  content: '';
  width: 10px !important;
  height: 51px !important;
  background-color: #ff000096 !important;
  position: absolute;
  left: 8px !important;
  top: 9px !important;
  border-radius: 0px !important;
}  */


.d-flex.group-head:before
{
  width:0px !important;
  height:0px !important;
}


.status-item
{
  height:32px !important;
}





.nav-toggle-workspace.show {
  display: block;
  width: 28px;
}



.border-hearder
{
  border:1px solid #EAEAEA;
}



/* .border-hearder:nth-child(odd)
{
  background-color:#f2f2f2;
}

.border-hearder:nth-child(even)
{
  background-color:#e3e1e1;
} */


.column-header{
  height: 35px;
  position : relative; 
  width:100%;
  border: 1px solid #EAEAEA;
  border-top:none;
  border-right:none;
}


.date-component {
  border-left: 1px solid #EAEAEA;
}


.grid-vertical {
  position: relative;
  top: 10px;
  left: 10px;
}


.holder__content
{
  position:relative;
}


i.bx.bx-dots-vertical-rounded.dot-icons {
  margin-top:11px;
  background-color: #4189f600 !important;
  color:#8a8888 !important;
  cursor:pointer;
}


i.fal.fa-filter.category-bars:hover
{
  background-color: #4288f5;
  border:1px solid #4288f5;
}



input.board-checkbox
{
    position: relative;
    top: 18px;
    left: 15px;
}

.board-dropdown-ellipsis
{
  position:relative;
  left: 2px;
}

.board-table-move
{
  color:#707070;
  position:relative;
  left: 5px;
  top: -1px;
}

.list-item-head
{
  font-size: 13px !important;
  padding: 3px 10px;
  width: 325px ;
  color : #545454 !important;
  position:relative;
  left: 19px;
  top: 5px;
}

.flex-item.border-hearder.data-0.title-column.bg-white {
  text-transform: capitalize;
}

/* 
.modal-backdrop {
  bug fix - no overlay 
  display: none !important;    
} */


.number-bottom > div {
  background-color: #c4c4c4 !important;
}

.tags-cell-component
{
  background-color: #c4c4c4 !important; 
}

button.close span {
  font-size: 14px;
}


.number-bottom .number-bottom-change
{
  background-color:#ffffff !important;
}


.column-header
{
  color:#fff !important;
  justify-content: space-between;
}

/* 
 .group-head
{
  margin-bottom:2px !important;
}  */


input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;

}


.navbar-text-ellipsis
{
  background-color: transparent;
  border-width: 0px;
  outline-color: #fff;
  text-align: initial;
  /* min-width: 150px; 
  max-width:150px; */
  width:100%;
  display: flex !important;
  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

.navbar-text-ellipsis:hover
{
  background-color: #007bff;
}

.navbar-text-ellipsis:hover>a.category-select
{
  background-color: #007bff;
}

a.category-select
{
  cursor:pointer;
}

.dropdown.workspace-ellipsis.pl-0.float-left {
  width: 100%;
}


li.nav-item.nav-inside-item.active {
  background-color: #4288f5;
}


li.nav-item.nav-inside-item.active button.nav-link.p-1.navbar-text-ellipsis
{
  color:#fff !important;
}


h5.mb-0 {
  font-size: 13px;
  justify-content: space-between;
  display: flex;
  color: #fff !important;
}


/* @media (min-width:1300px) and (max-width:1500px)
{
  li.nav-item.nav-inside-item.active .d-flex, li.nav-item.nav-inside-item.active .d-flex button
  {
    background-color:#ffffff;
  }

  li.nav-item.nav-inside-item button:hover
  {
    background-color:#ffffff;
  }
} */


.title-column 
{
  /* position:sticky;
  left:-1px; 
  z-index:1;   */
  background-color:#ffffff;
  height:34px;
  border-left:none !important;
  /* flex-grow:1;  */
  font-weight: bold;
  color: #353535 !important;
  -webkit-font-smoothing: subpixel-antialiased;
}


.drowpdown-edit.show
{
  z-index:9999;
}

.icons-right-board
{
  vertical-align: middle;
  color:#707070 !important;
  margin-right:30px;
}

ul.dropdown-menu.multi-level.animate.slideIn.status-columns.show
{
    position: absolute;
    left:-140px !important;
    top:10px !important;
}

.chatMainWrapper
{
  z-index:9999 !important;
}

img.users-avatar-shadow.rounded-circle
{
  width:30%;
  height:auto;  
}

ul.dropdown-menu.multi-level.animate.slideIn.board-first-ellipsis-dropdown.show {
  position: relative;
  left: -165px !important;
    top: 5px !important;
}




input.form-check-input {
  position: relative;
  top: 1px;
  right: 10px;
}


.form-check-label
{
  position:relative;
  left:15px;
}


input.form-check-input:hover {
  color: #fff;
  cursor:pointer;
}


.form-check:hover > label.form-check-label {
  color: #21386C;
  cursor: pointer;
}

i.newsub-icon
{
  color:#00ca72 !important;
  opacity:0.7;
}

.btn-nav-toggle {
  margin-top: -9px;
  margin-left: -10px;
}


/* .first-dropdown
{
  position:fixed !important;
  left:auto !important;
  top:auto !important;
} */

/* .board-dropdown
{
  position:fixed !important;
  left:auto !important;
  top:auto !important;
} */

/* .first-sub-workspace
{
  position:fixed !important;
  left:auto !important;
  top:auto !important;
} */

/* .newsub-dropdown
{
  position:fixed !important;
  left:auto !important;
  top:auto !important;
} */


ul.dropdown-menu.multi-level.animate.SlideIn.first-dropdown.show {
  min-width:240px;
}

ul.dropdown-menu.multi-level.animate.SlideIn.first-dropdown.show li {
  margin-left:10px;
  width:90%;
}

ul.dropdown-menu.multi-level.animate.SlideIn.first-dropdown.show li button i {
  margin-right:10px;
}



ul.dropdown-menu.multi-level.animate.SlideIn.first-sub-workspace.show
{
  margin-top: 0px !important;
  min-width:240px;
}


ul.dropdown-menu.multi-level.animate.SlideIn.first-sub-workspace.show li {
  margin-left:4px;
  width:90%;
}

ul.dropdown-menu.multi-level.animate.SlideIn.first-sub-workspace.show li button i {
  margin-right:10px;
}



ul.dropdown-menu.multi-level.animate.slideIn.board-dropdown.show + .btn-nav-toggle
{
  display:none !important;
}


ul.dropdown-menu.multi-level.animate.slideIn.board-dropdown.show
{
  margin-top: 0px !important;
}



ul.dropdown-menu.multi-level.animate.slideIn.newsub-dropdown.show
{
  margin-top: 0px !important;
}

ul.dropdown-menu.multi-level.animate.SlideIn.first-sub-workspace.show > i{
  color:red;
}

ul.dropdown-menu.multi-level.animate.slideIn.left-cog-workspace.show {
  position: absolute;
  left: 50px!important;
  top: 60px !important;
  width:200px;
}

ul.dropdown-menu.multi-level.animate.slideIn.left-workspace-filter-dropdown.show {
  position: absolute;
  left: auto !important;
  top: auto !important;
}

.filter-dropdown-en
{
  top:40px !important;
}

ul.dropdown-menu.multi-level.animate.slideIn.board-group-head-ellipsis.show {
  position: absolute;
  left: 10px !important;
}

ul.dropdown-menu.multi-level.animate.slideIn.board-items-dropdown.show {
  position: absolute;
  left: 12px !important;
  top: 0 !important;
}

ul.dropdown-menu.multi-level.animate.slideIn.status-dropdown.p-0.show {
  position: absolute;
  left: -50px !important;
  top: 0 !important;
  right: 0 !important;
  width:120px;
}


li.dropdown-item > button {
  font-weight: 300 !important;
}

i.group-move
{
  /* position: sticky !important; */
  top: 25px !important;
  margin-top: 9px !important;
  left: 67px;
  flex: 0 0 0%;
  display: block;
  color: #666;
}


div#dropdown-container {
  height: 34px;
}


ul.dropdown-menu.multi-level.add-right-column-dropdown {
  position: fixed;
  top: -30px !important;
  left: -318px !important;
}


ul.dropdown-menu.multi-level.show.subwork-dropdown {
  position: absolute;
  top: -8px;
  left: 10px;
  min-width:240px;
  overflow-y: scroll;
  height: calc(100vh - 80px);
}


ul.dropdown-menu.multi-level.first-subworkspace-dropdown.show {
  position: absolute;
  left: 9px !important;
  top: -7px !important;
  min-width:240px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 80px);
}


.last-updated-item-body div {
  font-weight: 100;
  font-size: 12.5px;
  position: relative;
  top: 4px;
}


.last-updated-item-body img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
}


.dropdown-label-suggest .dropdown-label-item {
    text-transform: lowercase;
}



.board-header-icons
{
  color: #ffffff;
}

.board-header-icons+span {
  margin-left: 7px !important;
}

.btn-group.newitem-btn button {
  color: #fff !important;
}

.search-outer {
  margin-top: 10px;
  margin: 0px !important;
}

a.nav-link.collapsed.py-1.pl-2 span {
  color: #000 !important;
}

ul.dropdown-menu li:hover .icons-right-board {
  color: #333 !important;
  background-color: #EAEAEA;
  padding: 5px;
  border-radius: 20px;
  transition:0.3s;
}


.board-right-ellipsis-icons
{
  vertical-align: middle ;
  color: #000000 ;
  text-transform : lowercase ;
}

.category-bars
{
  font-size: 14px;
  position: relative;
  top: -10px !important;
  color: #ffffff;
  background-color: #e8378f;
  border: 1px solid #db1c92;
  border-radius: 50px;
  padding: 9px 7px 5px;
}

.btn-group:not(.dropdown) > .btn {
  border: none !important;
}

.long-text-element.text-truncate.p-1 {
  min-width: 150px !important;
  width: 100% !important; 
  font-weight: 300;
  font-size: 14px;
}


.board-name:focus {
  outline-width: 1px;
  outline-style: dashed;
  /* height:20px; */
  outline-color: currentColor !important;
}


.board-description:focus {
    outline-style: none !important;
    outline-color: #007bff00;
}

h5.board-name.draw {
  font-size: 20px !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.data-title:hover::before
{
    background-color: #4288f5 !important;
    z-index: 9999 !important;
    position: absolute !important;
    left: -51px !important;
    text-transform:lowercase;
}

.last-updated-item-content.data-title:hover::after
{
  display: none;
}

.dropdown-menu.dropdown-timeline {
  left: -480px!important;
  top:0 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header
{
  font-weight:400 !important;
}


* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size:14.5px;
  height:28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  position: absolute;
  top: 32%;
  left: 12px;
  height: 15px;
  width: 15px;
  border-radius:3px;
  background-color: #EAEAEA;
  border: 1px solid #dad9d9;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #e8378f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* The container */
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #000;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
 top: 9px;
left: 9px;
width: 8px;
height: 8px;
border-radius: 50%;
background: white;
}

textarea{
  font-size: 13px;
  font-weight: 300;
}


textarea:focus-visible
{
  outline:none !important;
}


.w-100.pl-2.pr-2.board-member-detail {
  text-transform: lowercase;
  font-weight: 400;
  color: #4288f5;
}


.bx.bx-dots-vertical-rounded.dot-icons:hover {
  background-color: #4288f5 !important;
  border-radius: 20px !important; 
  color:#fff !important;
}

ul.dropdown-menu.multi-level.animate.slideIn.board-dropdown.show i.bx .bx-dots-vertical-rounded .dot-icons
{
  background-color: #4288f5 !important;
  border-radius: 20px !important;
  color: #fff;
}

.checkbox-div-table
{
  padding:9px 10px;
  width:30px;
}


.people-info-contact-detail div {
  text-transform: lowercase;
}


.people-info-body.p-2 h5 {
  text-transform: lowercase;
}


.col.inbox-head-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col.inbox-head-detail span.row {
  text-transform: lowercase;
  font-size:18px;
}

  .inbox-detailed-txt {
    border: 1px solid #dad8d8;
    height: 100%;
    overflow-y:auto;
    overflow-x:hidden;
    border-radius: 10px;
    padding: 20px 20px 0px;
    text-transform: lowercase;
}

.inbox-detailed-txt p{
  color: #000;
  font-weight: 600;
  font-style: italic;
  text-transform: lowercase;
}

.inbox-icons
{
  color:#4288f5;
  cursor:pointer;
}


.inbox-icons:hover
{
  color:#21519c;
}


.sun-editor .se-wrapper
{
  margin-top:10px;
  border: 1px solid #d2d0d0 !important;
  border-radius:10px !important
}

span.row.text-muted {
  font-size: 13.5px !important;
}

.inbox-detailed-txt div {
  border: none !important;
  line-height: 35px;
  float:left !important;
  width:100%;
  height:auto;
  padding-left: 20px;
  background-color: #e9ebec;
  border-radius: 10px;
}

.txt-inb p {
  font-weight: 300 !important;
}

.txt-inb p
{
  padding-left:20px;
}

.add-input:focus
{
  outline:none;
}

.selected {
  border: 1px solid #21386c !important;
  background-color: white !important;
  border-radius: 20px !important;
  padding: 8px 20px !important;
}

.data-title:hover::after{
  display:none !important;  
}


.circle-picker
{
  width:250px !important;
}


input[type="text"]:focus {
  outline: none;
}


.holder__title.board-name.list-item-head
{
    width: 240px;
    height: 25px;
    position: relative;
    top: 4px !important;
    -webkit-font-smoothing: subpixel-antialiased;
}

ul.dropdown-menu.multi-level.animate.slideIn.mainactiondrop.show {
  margin-top: 44px !important;
  padding:0px !important;
}


ul.dropdown-menu.multi-level.animate.slideIn.mainactiondrop li {
  height:42px !important;
  /* box-shadow: 0px 3px 6px 1px #e2dddd; */
  border-bottom: 1px solid #EAEAEA;
}



.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable p
{
  padding-top:4px !important;
}


.txt-inb div {
  color: #e8368f !important;
}


.autocomplete-dropdown-container {
  z-index: 99999 !important;
}


button.btn.btn-primary.arrow-workspace-one {
  border: 1px solid #c3c2c2 !important;
  background-color: #EAEAEA !important;
}

button.btn.btn-primary.arrow-workspace-one {
  border: 1px solid #c3c2c2 !important;
  background-color: #EAEAEA !important;
}

.readmore
{
  max-height:100% !important;
}

.overlay .closebtn {
  font-size: 40px;
  top: -15px;
  left: 35px;
}


#sort-dropdown>button:focus {
  background-color: #4288f5;
  color: #fff;
  border-radius:6px !important;
}

#sort-dropdown>button:active {
  background-color: #4288f5;
  color: #fff;
  border-radius:6px !important;
}


#sort-dropdown>button:visited {
  background-color: #4288f5;
  color: #fff;
  border-radius:6px !important;
}



i.fal.fa-comment-alt-dots.cursor-pointer.board-chat-icon
{
  color: #4288f5 !important;
  cursor:pointer !important;
}

i.fal.fa-comment-alt-dots.cursor-pointer.board-chat-icon:hover
{
  color: #4288f5 !important;
  transform: scale(1.1);
  transition: 0.3s;
  cursor:pointer;
}

.Toastify__toast--success {
  background: #4189f6 !important;
  height:30px;
  border-radius:20px !important;
  padding: 10px !important;
}


ul.dropdown-menu li:hover i.icon-style {
  /* background-color: #09F; */
  border-radius: 5px;
  color: #fff !important;
}

.dropdown-menu.multi-level.animate.slideIn.board-dropdown.show i.bx.bx-dots-vertical-rounded.dot-icons
{
  background-color:#0099ff;
}
 

i.bx.bx-chevron-right.collapse-icon.table-collapse-icon {
  position: sticky !important;
  left: 10px;
  flex: 0 0 0%;
  top: 4px !important;
  margin-top: 9px;
}

i.bx.bx-dots-vertical-rounded {
  position: sticky !important;
  left: 10px;
  margin-top: 3px;
  flex: 0 0 0%;
  color: rgb(199, 193, 193);
}


.dropdown.table-drop {
  /* position: sticky;
  left: 28px;
  margin-left: 0px; */
}

h4.holder__title.board-name.group-title-name {
  font-size:15px !important;
}



.tooltip-inner {
  background-color: #EAEAEA;
  }
  .tooltip.bs-tooltip-right .arrow:before {
      border-right-color: #EAEAEA !important;
  }
  .tooltip.bs-tooltip-left .arrow:before {
      border-left-color: #EAEAEA !important;
  }
  .tooltip.bs-tooltip-bottom .arrow:before {
      border-bottom-color: #EAEAEA !important;
  }
  .tooltip.bs-tooltip-top .arrow:before {
      border-top-color: #EAEAEA!important;
  }




.d-inline-flex.column-header:hover .board-name
{
  background-color: #EAEAEA;
}

.d-inline-flex.column-header:hover {
  background-color: #EAEAEA;
  width: 100%;
}


i.fas.fa-sort-down.column-down-arrow
{
  display: none;
}


.card.group-row.p-1.w-100.mb-1:hover{
  background-color:#EAEAEA;
  cursor:pointer
}

.title-column:hover
{
  background-color:#f1efef;
}


.title-column:hover + .dropdown-cell-item
{
  background-color:#f1efef;
}

.dropdown-cell-item
{
  background-color: #fff;
}

.dropdown-cell-item:hover
{
  background-color:#f1efef;
  cursor: pointer;
}

.flex-item.none:hover {
  background-color: #f1efef;
  cursor: pointer;
}


.column-header:hover i.fas.fa-sort-down.column-down-arrow
{
  display: block;
}

h5.board-name.draw
{
  outline:none;
}



.board-name:hover {
  border: 1px dashed #7e7d7d;
  outline:none !important;
}

.board-name:focus
{
  outline:none;
}


input.workspace-board:hover {
  border: 1px dashed #7e7d7d;
  margin-top: 2px !important;
  margin-left: 2px;
  border: 1px dashed #7e7d7d;
  height: 20px !important;
}

/* .list__item.drag-feature
{
  position: sticky;
  top: -1px;
  left: 0;
  z-index: 50; 
} */

.faa-ring
{
  color:#09F;
}

label.form-check-label {
  text-transform: capitalize;
}

.react-datepicker__input-container input {
  border: 1px solid #EAEAEA;
  font-weight:300 !important;
  font-size: 13.4px;
}

button.btn.btn-outline-secondary.btn-sm.btn-block.dropdown-toggle {
  border: 1px solid #EAEAEA;
}

select.form-control {
  border: 1px solid #EAEAEA;
  font-size: 13.4px;
}

.card.group-row.p-1.w-100.mb-1:hover {
  background-color: #ffffff !important;
}

.text-items {
  font-size: 13px !important;
  font-weight: 300 !important;
}

label.form-check-label.label-inp {
  text-transform: capitalize;
}

.holder__title.board-name.list-item-head:hover
{
  outline:none !important;
}


.board-table-row
{
  height:35px !important;
  border:1px solid #EAEAEA;
  /* margin-bottom: -1px; */
}

.board-name.column-text {
  margin-top: 2px !important;
  font-weight: bold !important;
    -webkit-font-smoothing: subpixel-antialiased;
    color: #353535 !important;
}

.activeClass
{
  background-color: #09F;
}


ul.dropdown-menu.animate.slidein.first-dropdown.show {
  position: fixed !important;
  top: 5px !important;
  left: 400px !important;
  width:240px !important;
  height: calc(100vh - 30px);
}

.dropdown-menu.multi-level.animate.slidein.board-dropdown.show
{
  position: fixed !important;
  top: 5px !important;
  left: 400px !important;
  bottom: 0 !important;
  height: calc(100vh - 30px);
}

ul.dropdown-menu.multi-level.animate.SlideIn.first-sub-workspace.show
{
  position: fixed !important;
  top: 5px !important;
  left: 400px !important;
  bottom: 0 !important;
  height: calc(100vh - 30px);

}


ul.dropdown-menu.multi-level.animate.slideIn.newsub-dropdown.show{
  position: fixed !important;
  top: 5px !important;
  left: 400px !important;
  width:240px !important;
  bottom: 0 !important;
  height: calc(100vh - 30px);
}



ul.dropdown-menu.multi-level.new-sub-dropdown.show
{
  position: fixed !important;
  top: 5px !important;
  left: 0px !important;
  width:240px !important;
  bottom: 0 !important;
  height: calc(100vh - 80px);
  overflow-y:auto;
}

ul.dropdown-menu.multi-level.subwork-dropdown
{
  overflow-y:auto;
}

span.account-user {
  text-transform: uppercase;
}

.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  height: 45px !important;
  font-size:16px !important;
  font-weight:400 !important;
}

.refMessageBubble.shadow.py-2.px-3.mb-3.border.small.w-100 {
  border: none !important;
}

.d-flex.w-100.py-2.border-bottom.align-items-center {
  text-transform: lowercase;
} 


ul.dropdown-menu.multi-level.animate.slideIn.column-header-dropdown.show {
  right: 0px;
  top:auto !important;
  margin-top:28px !important; 
  min-width:240px;
}

.dropdown-menu.animate.slidein.status-drp.show {
  right: 50px !important;
  left: auto !important;
}

.fltr-drp
{
  padding:0px !important;
}

.filter-component {
  padding: 7px 7px;
}

.filter-component::-webkit-scrollbar
{
  height:3px !important;
  color:#b2b2b2 !Important;
}

.filter-component::-webkit-scrollbar-thumb
{
  background-color:#b2b2b2 !Important;
}

.filter-component
{
  min-height: 320px !important;
}

.filter-cell
{
  overflow-y:inherit !important;
}

ul.filter-list h6
{
  padding:7px 10px;
  background-color: #ffffff;
}

ul.dropdown-menu.multi-level.animate.slideIn.fltr-drp.show {
  width: calc(100% - 0px);
  background-color:#f3f3f3;
}

.filter-cell div li:hover {
  background-color: #e2e1e100 !important;
  border:1px solid rgb(163, 161, 161);
}




* The container */
.container-checkbox-three {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size:14.5px;
  height:28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox-three input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox-three .checkmark {
  position: absolute;
  top: 45%;
  left: 12px;
  height: 15px;
  width: 15px;
  border-radius:3px;
  background-color: #EAEAEA;
  border: 1px solid #dad9d9;
}

/* On mouse-over, add a grey background color */
.container-checkbox-thee:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox-three input:checked ~ .checkmark {
  background-color: #e8378f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox-three .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox-three input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox-three .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


ul.dropdown-menu.multi-level.animate.slideIn.sort-drp.show {
  background-color: #21376b;
  color: #fff;
  height: auto;
  padding: 20px 10px 30px !important;
}

h5.sort-drp-title {
  font-size: 14.5px;
  margin-left: 7px;
  color: #fff;
}

button.btn.add-sort {
  font-weight: 400;
  background-color: #fff;
  color: #333;
  margin-left: 16px;
}

button.btn.add-sort i {
  color: #333 !important;

}

ul.dropdown-menu.multi-level.animate.slideIn.board-first-ellipsis-dropdown.show li
{
  height:42px;
  border-bottom:1px solid #EAEAEA;
}

.group-row
{
  height:34px !important;
}

.rdrDateDisplayWrapper
{
  background-color: rgb(239 242 247 / 0%) !important;
    border-radius: 15px !important;
}

.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: rgb(219 27 146) !important;
  box-shadow: 0 1px 2px 0 rgb(35 57 66 / 21%);
  border: 1px solid transparent;
}

.rdrMonth
{
  background-color: rgb(235 236 236) !important;
}

.rdrMonthAndYearWrapper
{
  background-color: rgb(235 236 236) !important;
  border-radius: 8px 8px 0px 0px;
}

.rdrMonthAndYearPickers
{
  background-color: #ffffff !important;
  border-radius: 50px;
}

.rdrMonthName
{
  color:#000 !important;
}

.rdrDayNumber span
{
  color:#000 !important;
}

.rdrNextPrevButton
{
  border-radius:50px !important;
  cursor:pointer !important;
}

.rdrDateDisplayItemActive input
{
  color:#fff !important;
}


.rdrWeekDay
{
  color:#000 !important;
}

.rdrDateDisplayItem input
{
  color:#fff !important;
}

.rdrDateDisplay
{
  color:#db1b92 !important;
}

.dropdown-label-item.file-dr {
  background-color: #d92190 !important;
  color: rgb(255, 255, 255);
}