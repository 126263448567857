.react-datepicker-wrapper {
    width: 100%!important;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    margin-top: 0;
    padding: 1px 10px 1px 20px;
    width: 100%!important;
}

.timeline-input {
    margin-top: 0!important;
    padding: 1px 10px 1px 20px!important;
    width: 100%;
}

@media(min-width:767px)
{
    .react-datepicker__header
    {
        background-color: #4288f5 !important;
    }
}