.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */   
    /* height: calc(100% - 1.3rem); */
    width: 90%;
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 140px;
    top: 10;
    overflow: hidden;
    border-radius:20px;
    background-color: #ffffff; /* Black fallback color */
    background-color: #ffffff; /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  
  /* Position the content inside the overlay */
  .overlay-content {
    position: relative;
    top: 9%; /* 25% from the top */
    width: 85%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-left: 2%;
  }
  
  /* The navigation links inside the overlay */
  /* .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block; 
    transition: 0.3s;
  } */
  
  /* When you mouse over the navigation links, change their color */
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  .globalsearch, .globalsearch:hover, .globalsearch:focus, .globalsearch:active {
    background: transparent;
    border: 0;
    border-style: none;
    border-color: transparent;
    outline: none;
    outline-offset: 0;
    box-shadow: none;
    border-bottom: 3px solid #21386c;
    width: 100%;
    color: #21386C;
    font-size: 55px;
    /* padding-left: 2.375rem; */
  }
  .globalsearch::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #6177a9;
    font-size: 55px;
    font-weight: 500;
  }
  .globalsearch::-moz-placeholder { /* Firefox 19+ */
    color: #6177a9;
    font-size: 55px;
    font-weight: 500;
  }
  .globalsearch:-ms-input-placeholder { /* IE 10+ */
    color: #6177a9;
    font-size: 55px;
    font-weight: 500;
  }
  .globalsearch:-moz-placeholder { /* Firefox 18- */
    color: #6177a9;
    font-size: 55px;
    font-weight: 500;
  }
  .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 6rem;
    text-align: center;
    pointer-events: none;
    color: #6177a9;
    font-size: 38px;
    right: 0;
}
  /* Position the close button (top right corner) */
  .overlay .closebtn {
    position: absolute;
    top: 0px;
    right: 25px;
    font-size: 36px;
  }
  
  /* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }
  .nav-tabs {
      border-bottom: none !important;
  }
  .nav-tabs .nav-link{
    background-color:transparent !important;
    color:#21386c;
    border:1px solid #21386C !important;
  }
  .nav-tabs .nav-link.active{
    background-color:#21386C !important;
  }
  .first-tab{
    padding: 10px !important; 
  }
  .nav-tabs .last-tab{
      background-color: #ef0993 !important;
  }
  .nav-tabs .last-tab.active{
    background-color:#ef0993 !important;
  }
  .g-search{
    padding: 0 im !important;
  }
  .g-search-card{
      background-color: transparent !important;
      border-color: transparent !important;
  }
  .tab-list-card{
      background-color: transparent !important;
      border: none !important;
      color: white !important;
  }
  .tab-list-card .card-title{
      color: white !important;
  }
  .tab-list-card .card-header{
     text-align: left;
     border-bottom: none !important;
  }
  .people-search{
    position: absolute;
    /* top: 50%; */
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .activity-content{
      margin-bottom: 15px !important;
  }
  .savesearchbtn{
    position: absolute;
    /* z-index: 2; */
    /* display: block; */
    /* width: 2.375rem; */
    /* height: 2.375rem; */
    /* line-height: 6rem; */
    text-align: center;
    /* pointer-events: none; */
    color: #6177a9;
    /* font-size: 38px; */
    right: 40px;
    top: 35px;
  }

  .search-header
  {
    background-color:#ef0993 !important;
    border-radius:10px !important;
  }
  
  .card-body.pt-1 {
    color: #21386c;
    margin-top: 20px;
    font-weight: 300;
}


.tab-pane.active {
  overflow-y: auto;
}

.tab-pane.active
{
    height:calc(100vh - 190px) !important;
    overflow-x:hidden;
}
