.App {
  text-align: center;
}

.title {
  font-family: "Roboto";
  font-size: 3rem;
  color: FFF;
  margin-bottom: 30px;
  margin-top: 30px;
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

.form-control.login {
  color: #ffffff !important;
}

.form-control:focus {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .1) !important;
}

.border-member-close {
  width: 22px;
  height: 20px;
  background-color: #555555;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  padding-left: 1px;
  cursor: pointer;
}

.data-title {
  position: relative;
}

.data-title:hover::before {
  content: attr(data-title);
  position: absolute;
  top: 0px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 2px;
  background: #000;
  color: #fff;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
}

.data-title:hover::after {
  content: '';
  position: absolute;
  bottom: 12px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-top: 8px solid #000;
}

.activity-col-title.data-title:hover::before {
  top: -18px;
  left: 0;
}

.activity-col-title.data-title:hover::after {
  bottom: 18px;
}

.register .css-2613qy-menu {
  color: #000 !important;
}

@media(min-width: 1024px) {
  .card-content.register {
    width: 96%;
  }
}

@media(min-width: 1200px) {
  .card-content.register {
    width: 94%;
  }
}

@media(min-width: 1400px) {
  .card-content.register {
    width: 90%;
  }
}

@media(min-width: 1600px) {
  .card-content.register {
    width: 88%;
  }
}

@media(min-width: 1800px) {
  .card-content.register {
    width: 80%;
  }
}
.forgot-password .modal-content {
  min-height: 259px;
  margin: 0 auto;
  padding: 23px 33px 20px 25px;
  max-width: 600px;
  border-radius: 25px;
  overflow: hidden;  
}

.forgot-password .modal-content .modal-header{
  height: 23px;
  background-color: #ffffff;
}

.forgot-password .modal-content .modal-header .close {
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 20px;
}