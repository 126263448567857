.chatMainWrapper{
    position: fixed;
    right: 0;
    top: 10px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 57%;
    height: calc(100% - 24px) !important;
    transition: .5s cubic-bezier(0.23, 1, 0.32, 1);
    transition : transform 150ms cubic-bezier(0, 0, 0.35, 1);
    background: #f7f7f7 !important;
    z-index: 999;
    padding: 10px;
    height: 100vh;
    /* transform: translateX(-40%); */
}

.sun-editor {

    border: 0 i !important;
    background-color: #fff0 !important;
}
    .sun-editor .se-toolbar {

        background-color: #fafafa00 !important;
        outline: 0 !important;

    }
    .se-resizing-bar.sun-editor-common {

        display: none !important;
    }


.chatBody{
overflow-y: auto;
}

.card.messages {
    border: none !important;
}

.card-header
{
    border-bottom:none !important;
}


.chatfooter{
    /* height: 14%; */
    /* height: 74px; */
    height: auto;
    position: absolute;
    bottom: -10px;
    border-bottom-left-radius: 20px;
    background: #182950;
    left: 0;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.header-title {
    background: #152346;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    padding: 8px 17px;
    font-size: 16px;
    font-weight: 300;
    height: 40px;
    margin-left: -10px;
    border-radius: 0 15px 0 0;
    min-width: 200px;
    text-transform: capitalize;
}

.editorTextarea{
    height: 115px !important;
    margin-bottom: 10px;
    background: white;
}

.row.tabcolummns {
    margin-left: -8px;
    border-left: none;
    border-top: none;
    margin-top: 13px;
    border-top: 1px solid #EAEAEA;
    padding: 9px 0px;
}


.chatHeader.row {
    margin-top: -11px !important;
}


.chatBody.py-2 {
    width: 99%;
    background-color: #ffffff ;
    border: 1px solid #EAEAEA;
    border-radius: 20px 20px 20px 20px;
}


.sun-editor .se-wrapper{
    /* height: 45px !important; */
    border: 1px solid #a0a0a0;
    border-radius: 25px;
}
.sun-editor-editable{
    padding: 10px !important;
}
.sun-editor{
border: none !important
}
.sun-editor .se-toolbar{
    background-color: rgb(232, 55, 143) !important;
    border-radius: 15px;
    margin-bottom: 10px !important;
}
.sun-editor button {
    color: #ffffff !important;
}

.sun-editor button:hover {
    color: #000 !important;
}

.sun-editor .se-toolbar {
    /* display: block; */
    position: absolute !important;
    width: 100% !important;
    top: -90px !important;
}

/* chat buuble start */

.col-md-2, .col-md-10{
    padding:0;
}
.panel{
    margin-bottom: 0px;
}

.chat-window > div > .panel{
    border-radius: 5px 5px 0 0;
}
.icon_minim{
    padding:2px 10px;
}
.msg_container_base{
  margin: 0;
  height:calc(100% - 50px);
  overflow-y:auto;
  overflow-x:hidden;
}

.msg_receive{
    padding-left:0;
    margin-left:0;
}
.msg_sent{
    padding-bottom:20px !important;
    margin-right:0;
}
.messages {
  /* background: white;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width:100%; */
  border-radius: 20px !important;
}
 .messages > p {
  
    margin: 0 0 0.2rem 0;
  } 
.messages > time {
    font-size: 12px;
    color: #ccc;
}
.msg_container {
    padding: 0px 10px;
    /* overflow: hidden; */
    display: flex;
}
img {
    display: block;
    width: 100%;
}
.chat-avatar {
    position: relative;
}
.chat-avatar img{
    border-radius: 100px;
    width:80%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.base_receive > .chat-avatar:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 5px solid #FFF;
    border-left-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}
.base_sent > .chat-avatar:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 5px solid white;
    border-right-color: transparent;
    border-top-color: transparent;
 
}

.msg_sent > time{
    float: right;
}



.msg_container_base::-webkit-scrollbar-track
{
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar
{
    width: 4px;
    background-color: #F5F5F5;
}

.msg_container_base::-webkit-scrollbar-thumb
{
    background-color: #555;
}

/* table, th, td {
    border: 1px solid rgb(185, 185, 185) !important;
    padding: 10px !important;
  } */


.messages img{
    max-height: 200px;
    max-width: 200px;
}
/* chat buble end */

.backfrop{
    position: fixed;
    /* background: rgb(24 41 79 / 75%); */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 111;
}


.se-resizing-bar.sun-editor-common{
    display: none;
}

.border-radius-25{
    border-radius: 25px !important;
}

.refMessageBubble{
    position: absolute;
    top: -92px;
    border-radius: 15px 15px 0 0;
    width: 85%;
    background: #161f38;
    color: white;
    border:none !important;
}

.refMessageBubble.shadow.py-2.px-3.mb-3.border.small.w-100 div {
    text-transform: lowercase;
}

.card-body.msgbody.p-2 {
    font-weight: 400 !important;
    font-size:14px;
    font-style: normal;
    color: #c52e86;
}



.usersuggestions{
    position: absolute;
    top: -200px;
    border-radius: 15px 15px 0 0;
    width: 100%;
    background: #21386C;
    color: rgb(209, 205, 205);
    height: 190px;
    overflow-y: auto;
}

.quotedmessage{
    background: rgb(221, 221, 221);
    border-radius: 10px;
    padding: 5px 10px;
    margin-bottom: 10px;
    font-size: 12px;
}

/* .quotedmessage .quotedmessagebody{
    margin-bottom: 10px;
} */


.col-xl-6.col-6.chat-head-text {
    text-transform: lowercase;
    font-family: 'myriad-pro';
    color: #18294f;
    padding: 0px 20px;
}


.customDropzone{
    position: absolute;
    width: 100%;
    height: 370px;
    border: 1px dotted #c3c3c3;
    z-index: 100;
    left: 0;
    background: #fafafa;
    /* background: #eee; */
    bottom: 65px;
}

figure{
    width: auto !important;
    height: auto !important;
    padding-bottom: 10px !important;
   
}

figure video, figure img, figure audio{
    height: 170px !important;
    width: 170px !important;
    background: gray;
    border-radius: 10px;
    margin-left: 10px;
}

.nonmedia{
    height: 170px !important;
    width: 170px !important;
    background: gray;
    border-radius: 10px;
    margin-left: 10px;
    text-align: center;
    padding: 10px;
    float: left;
    font-size: medium;
}
.nonmedia a{
    color:white !important;
}
   
.se-component.se-image-container,.se-component.se-video-container{
    float: left;
}

.thumb_panel{
    position: absolute;
    top:10px;
}

.dropzone{
    height: 130px;
    border:none !important;
    outline: none;
}


.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }
  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
  }

  .round-button {
    display:block;
    width:35px;
    height:35px;
    line-height:35px;
    border: 1px solid #f5f5f5;
    border-radius: 50%;
    color:#f5f5f5;
    text-align:center;
    text-decoration:none;
    background: rgb(232, 55, 143);
    box-shadow: 0 0 3px gray;
    font-size:20px;
    font-weight:bold;
    cursor: pointer;
}

.round-icon {
    font-size: 20px;
    cursor: pointer;
    color: white !important;
    margin-right: 23px !important;
    margin-top: 10px;
}

.round-icon:hover{
    color: rgb(231, 231, 231) !important;
}

.round-button:hover {
    background: #4288f5;
}
.round-button .fa{
    font-size: 15px !important;
    color: white !important;
    margin-top: 10px !important;
}
  
.text-pink{
    color: rgb(232, 55, 143) !important;
}
.bg-pink{
    background-color: rgb(232, 55, 143) !important;
    color: white;
}
.rightcolumn:before{
display: none !important;
}
.nomsg{
    margin-top: 40px;
    border: 1px dashed gray;
    padding: 35px;
    font-size: x-large;
    font-weight: 300;
    display: inline-block;
}

.cellitems{
    display: inline-block;
    /* margin-left: 10px; */
    /* min-width: 140px; */
    min-width:80%;
}


.msgheader{
    border-radius: 20px 20px 0px 0px;
    background: #d2d2d2 !important;
}

.msgbody{
    border-radius: 0px 0px 20px 20px;
    background: #fff !important;
    border: 1px solid #d2d2d2;
}

.messages p{
        margin-bottom: 0.1rem !important;
    
}

.blink_chatmsg {
    background: rgb(232, 55, 143) !important;
   animation-name: blinker;
   animation-duration: 3s;
   animation-timing-function: linear;
   animation-iteration-count: infinite;
  }
  
  @keyframes blinker {  
   0% { opacity: 1.0; }
   50% { opacity: 0.0; }
   100% { opacity: 1.0; }
  }

  .emojicontainer {
    position: absolute;
    top: -333px;
    left: -4px;
}

.likecounter{
    font-size: 7px !important;
    position: absolute;
    top: -7px;
    padding: 3px !important;
    background: #ff5c78;
    left: -3px;
}

.preview-badge{
    position: absolute;
    left: 12px;
    top: 0;
    font-size: 10px !important;
    color: white !important;
}

.dropdownh:hover>.dropdown-menu {
    display: block !important;
  }
  
  .dropdownh>.dropdown-toggle:active {

      pointer-events: none !important;
  }

  /* , .msgbody video, .msgbody audio,.msgbody .nonmedia */
  .msgbody img,  .msgbody video, .msgbody audio,.msgbody .nonmedia{
      float: left;
      height: 170px;
      width: 170px;
      margin-right: 10px;
  }


  .reminderBox{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
  }

   .msgbody {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  /* table, td, th,tr {  
    border: 1px solid rgb(53, 53, 53) !important;
    text-align: left;
  } */
  
  table {
    border-collapse: collapse;
    width: 95% !important;
  }
  
  th, td,tr {
    padding: 7px !important;
  }

  .sun-editor .se-wrapper
  {
      height:35px !important;
  }

  .sun-editor .se-wrapper .se-wrapper-inner{
      height: auto !important;
  }
  .sun-editor .se-wrapper .se-wrapper-wysiwyg{
      padding: 0px 10px !important;
  }

  .sun-editor-editable p {
    display: block;
    margin: 0 0 -3px !important;
}

.font-weight-light.float-left.mt-2 {
    width: 100%;
}

.chat-header-section
{
    display: flex;
   background-color: #152346; 
    margin-left: 5px;
    border-radius: 20px 20px 0px 0px;
}

.chat-close-icon
{
    font-weight:300;
    cursor: pointer;
    font-size:20px;
    color:#fff;
    width:40px;
    height: 100%;
    align-items: center;
    display: flex;
    position: relative;
    top: 2px;

}


.chat-close-icon:hover
{
    color:#ff5c78;
}

.add-column-drop-chat
{
    height: 100%;
    width:100%;
    display: flex;
    justify-content: flex-end;
    align-items: right;
    margin-right: 70px;
}


.add-column-drop-chat a
{
    color: #152346 !important;
}

.dropdown-menu.animate.slidein.options-dropdown.show {
    top: 45px !important;
    left: auto !important;
    right: 0 !important;
    width: 90%;
    max-height: 500px;
    padding: 15px !important;
}

form.mt-n3.mb-n2.options-form
{
    margin-bottom:15px !important;
}

h5.chat-txt-hd {
    font-weight: 600;
    font-size: 14.5px;
    padding: 8px 0px;
    border-radius: 4px;
    color: #21376b;
    border-bottom: 1px solid #EAEAEA;
}

h5.chat-txt-form
{
    font-weight:300;
    font-size:14.5px;
    margin-bottom:30px;
}

.form-field-design
{
    border:1px solid #f1f0f0;
    margin-bottom:10px;
    height: 8%;
    width:25%;
    float:left;
    border-radius:5px;
    box-shadow: -1px 0px 7px 0px #eaeaea;
    padding-left: 35px !important;
    margin-right: 10px;
}

form.mt-n3.mb-n2.options-form {
    height: 390px;
    width:120%;
    margin: 20px 0px 20px !important;
    overflow-y: auto;
}


.checkbox-div-table-one
{
    padding: 0px 10px;
    width: 100%;
}


* The container */
.container-checkbox-one {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size:14.5px;
  height:28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox-one input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox-one .checkmark {
  position: absolute;
  top: 24%;
  left: 12px;
  height: 17px;
  width: 17px;
  cursor:pointer;
  border-radius:3px;
  background-color: #EAEAEA;
  border: 1px solid #dad9d9;
  border-radius:50px;
}

/* On mouse-over, add a grey background color */
.container-checkbox-one:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox-one input:checked ~ .checkmark {
  background-color: #e8378f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox-one .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox-one input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox-one .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/* The container */
.container-radio-one {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio-one input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-radio-one .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-radio-one:hover input ~ .checkmark {
  background-color: #000;
}

/* When the radio button is checked, add a blue background */
.container-radio-one input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio-one .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio-one input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio-one .checkmark:after {
 top: 9px;
left: 9px;
width: 8px;
height: 8px;
border-radius: 50%;
background: white;
}


.dropleft .dropdown-menu:before
{
    content:none !important;
}

.nav.nav-tabs {
    margin-bottom: 1rem;
    border-bottom-color: #ededed !important;
    border-bottom: 1px solid #EAEAEA !important;
}

ul.nav.nav-tabs li {
    width: 120px;
}


ul.nav.nav-tabs li{
    justify-content: flex-start;
    width: 25% !important;
    align-items: center;
    padding: 0px 20px 0px;
    float: left;
    display: flex;
}

ul.nav.nav-tabs li a.active {
    color: #152346;
    margin: 0;
    background-color: #e8368f00;
    border-bottom: 2px solid #152346;
    padding: 9px 0px;
    width:100%;
    justify-content: center !important;
    display:flex;
    justify-content: center;
    border-radius: 20px 20px 0px 0px;
}

ul.nav.nav-tabs li {
    width: 120px;
    padding: 0px 20px;
}

ul.nav.nav-tabs li a {
    color: #4c4b4b;
}

.row.option-drp {
    display: flex;
    justify-content: flex-end;
}