.my-table th{
  background-color: #5a8dee !important;
}
.my-table tr {
    color: gray;
    font-weight: bold;
  }
  ._1OmBB tbody tr:hover {
    color:  #5a8dee;
    font-weight: bold;
  }
  
  .rft-pagination-button{
    background-color: #5a8dee !important;
    border: 1px solid #5a8dee !important;
    color: honeydew;
  }

  .rft-pagination-button:hover{
    border: 1px solid #5a8dee !important;
    color: honeydew;

  }
  ._1OmBB thead tr{
    background-color : #5a8dee !important; 
  }